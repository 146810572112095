export const FirebaseConfig = {
	"projectId": "zimride-zw",
	"appId": "1:417235060039:web:34aff1faae33430ed47862",
	"databaseURL": "https://zimride-zw-default-rtdb.firebaseio.com",
	"storageBucket": "zimride-zw.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBRNFaOQVBmNxJ2G_MIs2XVGAYD4YmgwU8",
	"authDomain": "zimride-zw.firebaseapp.com",
	"messagingSenderId": "417235060039",
	"measurementId": "G-412W9GY5CR"
};